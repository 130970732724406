import type { Nodes } from "hast";
import { Show } from "solid-js";
import { Dynamic } from "solid-js/web";

import styles from "~/components/PostLinkItem.module.scss";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import { fragmentToJsx } from "~/utils/fragmentToJsx";
import { generateWidthThumbs } from "~/utils/generateThumbs";
import { toYearMonthPair } from "~/utils/toYearMonthPair";

interface IndividualPostLinkProps {
  readonly title: Nodes;
  readonly lead: Nodes;
  readonly slug: string;
  readonly sticky?: boolean;
  readonly categoryTitle: string;
  readonly categorySlug: string;
  readonly publishedAt: Date;
  readonly cover: string;
  readonly level: "h2" | "h3";
}

export default function PostLinkItem(props: IndividualPostLinkProps) {
  return (
    <div class={styles.wrapper}>
      <div class={styles.image}>
        <img
          src={props.cover}
          srcSet={generateWidthThumbs(props.cover, 16 / 9)}
          alt=""
          loading="lazy"
          sizes="auto"
        />
      </div>
      <Show when={props.sticky}>
        <div class={styles.sticky}>
          ★<span class={rootStyles["sr-only"]}>Artigo fixo</span>
        </div>
      </Show>
      <div class={styles.meta}>
        <time dateTime={props.publishedAt.toISOString()}>
          {props.publishedAt.toLocaleString(import.meta.env.VITE_LOCALE, {
            dateStyle: "long",
            timeStyle: "short",
            timeZone: import.meta.env.VITE_TZ,
          })}
        </time>
        <SmartA class={styles.category} href={`/${props.categorySlug}`}>
          {props.categoryTitle}
        </SmartA>
      </div>
      <div class={styles.link}>
        <Dynamic component={props.level}>
          <SmartA
            href={`/${props.categorySlug}/${toYearMonthPair(props.publishedAt)}/${props.slug}`}
          >
            {fragmentToJsx(props.title)}
          </SmartA>
        </Dynamic>
        <p class={styles.lead}>{fragmentToJsx(props.lead)}</p>
      </div>
    </div>
  );
}
